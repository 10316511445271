<template>
  <div>
    <router-link class="cursor-pointer" :to="{name: 'TagDetail', params: {name: 'NhạcNhẹ'}}">
      <div class="rounded-md overflow-hidden relative w-full h-36">
        <div class="absolute w-full h-3/4 -bottom-12 bg-gradient-to-b from-transparent to-gray-800 z-10">
        </div>
        <img src="assets/images/bcdcnt/nhac-nhe.jpg" class="absolute w-full h-full object-cover" alt="">
        <div class="absolute bottom-0 w-full p-3 text-white z-20 font-semibold text-lg">Nhạc nhẹ</div>
      </div>
    </router-link>
  </div>
</template>

<script>

export default {
  name: 'NhacNhe'
}
</script>
