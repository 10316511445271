<template>
  <div>
    <div class="lg:flex lg:space-x-10">
      <div class="w-full lg:px-20">
        <div class="relative lg:-mx-20" uk-slider="autoplay:true;finite:false;autoplay-interval:3000">
          <div class="uk-slider-container">
            <ul class="uk-slider-items uk-child-width-1-5@m uk-child-width-1-5@s uk-child-width-1-2 uk-grid-small uk-grid"
                v-if="mostViewed && mostViewed.length">
              <li v-for="(item, index) in mostViewed" :key="'mostViewed' + index">
                <div class="card" v-if="isSongable(item['__typename'])">
                  <router-link
                      :to="{name: 'SongDetail', params: {id: item.id, slug: item.slug, prefix: getPrefixFromSongType(item['__typename'])}}">
                    <img :alt="item.title"
                         :src="item.thumbnail ? item.thumbnail.url : item.artists && item.artists.data && item.artists.data[0] && item.artists.data[0].avatar ? item.artists.data[0].avatar.url : 'https://static.bcdcnt.net/assets/images/group/group-3.jpg'"
                         class="h-44 object-cover rounded-t-md shadow-sm w-full">
                  </router-link>
                  <div class="p-3">
                    <h4 class="text-base font-semibold mb-0.5 line-clamp-1">
                      <router-link
                          :to="{name: 'SongDetail', params: {id: item.id, slug: item.slug, prefix: getPrefixFromSongType(item['__typename'])}}">
                        {{ item.title }}
                      </router-link>
                    </h4>
                    <p class="font-medium text-sm people-in-song line-clamp-1" v-if="item.artists && item.artists.data">
                      <people-of-song :people="item.artists.data" type="artist"/>
                    </p>
                    <p class="font-medium text-sm people-in-song line-clamp-1" v-if="item.users && item.users.data">
                      <users-of-song :people="item.users.data" type="user"/>
                    </p>
                  </div>
                </div>
                <div class="card" v-if="isPeople(item['__typename'])">
                  <router-link
                      :to="{name: 'PeopleDetail', params: {slug: item.slug, prefix: getRoutePrefixPeople(item['__typename'])}}">
                    <img :alt="item.title" :src="item.avatar ? item.avatar.url : 'https://static.bcdcnt.net/assets/images/group/group-3.jpg'"
                         class="h-44 object-cover rounded-t-md shadow-sm w-full">
                  </router-link>
                  <div class="p-3">
                    <h4 class="text-base font-semibold mb-0.5 line-clamp-1">
                      <router-link
                          :to="{name: 'PeopleDetail', params: {slug: item.slug, prefix: getRoutePrefixPeople(item['__typename'])}}">
                        {{ item.title }}
                      </router-link>
                    </h4>
                    <p class="font-medium text-sm people-in-song line-clamp-1">
                      {{ getNameFromPeoplePrefix(item['__typename']) }}
                    </p>
                  </div>
                </div>
                <div class="card" v-if="item['__typename'] === 'Document'">
                  <router-link :to="{name: 'DocumentDetail', params: {id: item.id, slug: item.slug}}">
                    <img :alt="item.title"
                         :src="item.thumbnail ? item.thumbnail.url : 'https://static.bcdcnt.net/assets/images/group/group-3.jpg'"
                         class="h-44 object-cover rounded-t-md shadow-sm w-full">
                  </router-link>
                  <div class="p-3">
                    <h4 class="text-base font-semibold mb-0.5 line-clamp-1">
                      <router-link :to="{name: 'DocumentDetail', params: {id: item.id, slug: item.slug}}">
                        {{ item.title }}
                      </router-link>
                    </h4>
                    <p class="font-medium text-sm people-in-song line-clamp-1">
                      Tư liệu
                    </p>
                  </div>
                </div>
              </li>
            </ul>

            <a class="absolute bg-white bottom-1/2 flex items-center justify-center p-2 left-2 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white"
               href="#" uk-slider-item="previous"> <i class="icon-feather-chevron-left"></i></a>
            <a class="absolute bg-white bottom-1/2 flex items-center justify-center p-2 right-2 rounded-full shadow-md text-xl w-9 z-10 dark:bg-gray-800 dark:text-white"
               href="#" uk-slider-item="next"> <i class="icon-feather-chevron-right"></i></a>

          </div>
        </div>
      </div>
    </div>
    <div class="lg:flex lg:space-x-10 mt-10">
      <div class="lg:w-3/5 space-y-7 mb-7">
        
        <div class="card p-3">
          <latest-song-box/>
        </div>

        <div class="card p-3">
          <discussion-box/>
        </div>

        <div class="card p-3">
          <h2 class="text-xl font-semibold">Tư liệu âm thanh, hình ảnh</h2>
          <div class="grid grid-cols-2 md:grid-cols-2 gap-2 mt-4">
            <div v-for="imageDocument in imageDocuments.data" :key="'imageDocument' + imageDocument.id">
              <div
                  class="rounded-xl overflow-hidden relative w-full h-44 cursor-pointer transform hover:scale-105 duration-300 hover:shadow-md">
                  <img v-if="imageDocument.type === 'image'"
                       :src="imageDocument.thumbnail ? imageDocument.thumbnail.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-1.jpg'"
                       class="absolute w-full h-full object-cover" :alt="imageDocument.title">
                  <iframe style="position:absolute;top:0px;width:100%;height:100%;" frameborder="0" scrolling="no" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen :src="origin + '/embed?src=' + imageDocument.file.audio_url + '&class=iframe'" v-if="imageDocument.type === 'audio' && imageDocument.file.type === 'audio'"/>
                  <iframe style="position:absolute;top:0px;width:100%;height:100%;" frameborder="0" scrolling="no" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen :src="origin + '/embed?src=' + imageDocument.file.video_url + '&class=iframe'" v-if="imageDocument.type === 'audio' && imageDocument.file.type === 'video'"/>
              </div>
              <div class="pt-3">
                <router-link
                    class="font-semibold line-clamp-2"
                    :to="{name: 'DocumentDetail', params: {id: imageDocument.id, slug: imageDocument.slug}}"
                    :title="imageDocument.title">
                  {{ imageDocument.title }}
                </router-link>
                <div class="pt-2">
                  
                  <div class="flex space-x-2 items-center text-sm mt-1">
                    <router-link
                        class="text-sm font-medium"
                        :to="{name: 'UserDetail', params: {id: imageDocument.uploader.id}}"
                        :title="'bởi' + imageDocument.uploader.username">
                      {{ imageDocument.uploader.username }}
                    </router-link>
                    <div class="md:block hidden">·</div>
                    <div>{{ timeago(imageDocument.created_at) }}</div>
                    <div class="md:block hidden">·</div>
                    <div> <i class="uil uil-eye"></i> {{ numberFormat(imageDocument.views) }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <v-pagination
                class="justify-content-end"
                v-if="imageDocuments.paginatorInfo.lastPage > 1"
                v-model="imageDocuments.paginatorInfo.currentPage"
                :pages="imageDocuments.paginatorInfo.lastPage"
                :range-size="1"
                active-color="#DCEDFF"
                @update:modelValue="loadImageDocuments"
            />
          </div>
        </div>

        <div class="card p-3">
          <discussion-young-artist-box/>
        </div>

        <div class="card p-3">
          <div v-if="documents.data && documents.data.length">
            <div class="lg:flex lg:space-x-6 py-5" v-for="(document, index) in documents.data"
                 :key="'document' + index">
              <router-link :to="{name: 'DocumentDetail', params: {id: document.id, slug: document.slug}}"
                           :title="document.title">
                <div class="lg:w-40 w-full h-40 overflow-hidden rounded-lg relative shadow-sm">
                  <img :src="document.thumbnail ? document.thumbnail.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-1.jpg'"
                       :alt="document.title" class="w-full h-full absolute inset-0 object-cover">
                </div>
              </router-link>

              <div class="flex-1 lg:pt-0 pt-4">
                <router-link :to="{name: 'DocumentDetail', params: {id: document.id, slug: document.slug}}"
                             :title="document.title" class="text-xl font-semibold line-clamp-2">
                  {{ document.title }}
                </router-link>
                <p class="line-clamp-2 pt-1">
                  {{ stripContent(document.content, 100) }}
                </p>

                <div class="flex items-center pt-3">
                  <div class="flex items-center">
                    <ion-icon name="eye-outline" class="text-xl mr-2 md hydrated" role="img"
                              aria-label="thumbs up outline"></ion-icon>
                    {{ numberFormat(document.views) }}
                  </div>
                  <div class="flex items-center mx-4">
                    <ion-icon name="alarm-outline" class="text-xl mr-2 md hydrated" role="img"
                              aria-label="thumbs up outline"></ion-icon>
                    {{ timeago(document.created_at) }}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <!-- <div class="card p-3">
          <ul v-if="activities.edges && activities.edges.length">
            <li class="" v-for="(activity, index) in activities.edges" :key="'activity' + index">
              <a href="javascript:void(0)" class="relative flex py-3 hover:bg-gray-100 rounded-md px-2 mb-2 space-x-4">
                <div class="h-12 w-12 flex-shrink-0">
                  <img
                      :src="activity.node && activity.node.user && activity.node.user.avatar ? activity.node.user.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-1.jpg'"
                      class="roĩunded-full object-cover h-full w-full">
                </div>
                <span class="bg-blue-500 absolute mt-8 ml-7 text-white p-2 rounded-full w-7 h-7 flex items-center">
                  <i class="icon-feather-heart" v-if="activity.node.action === 'love_comment'"></i>
                  <i class="icon-feather-at-sign" v-if="notification.node.action === 'mention'"></i>
                </span>
                <div class="flex-1">
                  <p>
                    <strong>{{ activity.node.user.username }}</strong> {{ activity.node.action }}
                  </p>
                  <time>{{ timeago(activity.node.created_at) }}</time>
                </div>
              </a>
            </li>
          </ul>
        </div> -->
      </div>

      <div class="lg:w-2/5 w-full space-y-7">
        <sticky-people/>

        <div class="card p-3">
          <latest-comment/>
        </div>

        <div class="card p-3">
          <h2 class="text-xl font-semibold">Tag nổi bật</h2>
          <div class="tags mt-3">
            <router-link class="cursor-pointer" :title="'Tag ' + tag.name" :to="{name: 'TagDetail', params: {name: tag.slug}}" v-for="tag in tags" :key="'tag' + tag.id">
              <span class="keyword px-2">
                <span class="keyword-text">{{ tag.name }} ({{ tag.sheetCount }})</span>
              </span>
            </router-link>
          </div>
        </div>

        <top-upload/>

        <top-like/>

        <top-point :show-paging="false"/>

        <statistic-listen :show-paging="false"/>

        <div style="display: none;">
          <nhac-tien-chien class="mt-2"/>
          <nhac-cach-mang class="mt-2"/>
          <nhac-nhe class="mt-2"/>
          <nhac-thieu-nhi class="mt-2"/>
          <nhac-nuoc-ngoai class="mt-2"/>
        </div>

        <right/>
      </div>
    </div>
  </div>
</template>

<script>

import ApiService from "../../core/services/api.service";
import {
  changePageTitle, getNameFromPeoplePrefix, getPrefixFromSongType, getRoutePrefixPeople, isPeople, isSongable,
  loadNotifications,
  numberFormat,
  stripContent, thousandFormat,
  timeago
} from "../../core/services/utils.service";
import PeopleOfSong from "../song/PeopleOfSong";
import NhacTienChien from "../song/categories/NhacTienChien";
import NhacCachMang from "../song/categories/NhacCachMang";
import NhacNhe from "../song/categories/NhacNhe";
import NhacThieuNhi from "../song/categories/NhacThieuNhi";
import NhacNuocNgoai from "../song/categories/NhacNuocNgoai";
import DiscussionBox from "../discussion/DiscussionBox";
import DiscussionYoungArtistBox from "../discussion/DiscussionYoungArtistBox";
import Right from "../right/Right";
import LatestComment from "../comment/LatestComment";
import UsersOfSong from "../song/UserOfSong";
import LatestSongBox from "../song/LatestSongBox";
import StatisticListen from "../statistic/StatisticListen";
import TopPoint from "../statistic/TopPoint";
import TopLike from "../statistic/TopLike";
import TopUpload from "../statistic/TopUpload";
import StickyPeople from "../people/StickyPeople";

export default {
  name: 'Index',
  components: {
    StatisticListen,
    UsersOfSong,
    LatestSongBox,
    LatestComment,
    Right,
    DiscussionBox,
    DiscussionYoungArtistBox,
    PeopleOfSong,
    NhacTienChien,
    NhacCachMang,
    NhacNhe,
    NhacThieuNhi,
    NhacNuocNgoai,
    TopPoint,
    TopLike,
    TopUpload,
    StickyPeople
  },
  data() {
    return {
      playlists: {
        data: []
      },
      artists: {
        data: []
      },
      composers: {
        data: []
      },
      poets: {
        data: []
      },
      documents: {
        data: []
      },
      imageDocuments: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      origin: window.location.origin,
      hotSongs: [],
      mostViewed: [],
      topUpload: [],
      topUploadDocument: [],
      topComment: [],
      topCommentLove: [],
      tags: [],
      activities: {
        edges: []
      }
    }
  },
  methods: {
    loadDocuments() {
      let query = `query {
        documents(first:3, orderBy: [{column: "id", order:DESC}], where: {AND: [{column: "type", value: "news"}, {column: "status", value: 1}]}) {
          data {
            id
            title
            slug
            views
            content
            created_at
            thumbnail {
              url
            }
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.documents) {
              this.documents = data.data.documents;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadImageDocuments() {
      let query = `query($page: Int) {
        documents(page: $page, first:4, orderBy: [{column: "id", order:DESC}], where: {AND: [{column: "type", value: ["image", "audio"], operator: IN}]}) {
          data {
            id
            title
            slug
            views
            type
            created_at
            thumbnail {
              url
            }
            uploader {
              id
              username
            }
            file {
              type
              audio_url
              video_url
            }
          }
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.imageDocuments.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.documents) {
              this.imageDocuments = data.data.documents;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadHotSongs() {
      let query = `query {
        playlists(first: 1, where: {AND: [{column: "is_sticky", value: "Y"}]}) {
          data {
            items(first: 20, orderBy: [{column: "position", order: ASC}]) {
              data {
                object {
                  __typename
                  id
                  title
                  slug
                  thumbnail {
                    url
                  }
                  file {
                    audio_url
                  }
                  ... on Song {
                    artists(first: 10) {
                      data {
                        title
                        slug
                        avatar {
                          url
                        }
                      }
                    }
                  }
                  ... on Folk {
                    artists(first: 10) {
                      data {
                        title
                        slug
                        avatar {
                          url
                        }
                      }
                    }
                  }
                  ... on Instrumental {
                    artists(first: 10) {
                      data {
                        title
                        slug
                        avatar {
                          url
                        }
                      }
                    }
                  }
                  ... on Poem {
                    artists(first: 10) {
                      data {
                        title
                        slug
                        avatar {
                          url
                        }
                      }
                    }
                  }
                  ... on Karaoke {
                    user {
                      id
                      username
                      avatar {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }`;

      ApiService.graphql(query, {id: 12})
          .then(({data}) => {
            if (data.data && data.data.playlists && data.data.playlists.data && data.data.playlists.data[0] && data.data.playlists.data[0].items && data.data.playlists.data[0].items.data) {
              this.hotSongs = [];
              data.data.playlists.data[0].items.data.map((item) => {
                this.hotSongs.push(item.object);
              });
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    
    
    
    loadMostViewed() {
      let query = `query {
        mostViewed(first: 20) {
          data {
            object {
              __typename
              ... on Song {
                id
                title
                slug
                thumbnail {
                  url
                }
                artists(first: 10) {
                  data {
                    title
                    slug
                    avatar {
                      url
                    }
                  }
                }
              }
              ... on Folk {
                id
                title
                slug
                thumbnail {
                  url
                }
                artists(first: 10) {
                  data {
                    title
                    slug
                    avatar {
                      url
                    }
                  }
                }
              }
              ... on Instrumental {
                id
                title
                slug
                thumbnail {
                  url
                }
                artists(first: 10) {
                  data {
                    title
                    slug
                    avatar {
                      url
                    }
                  }
                }
              }
              ... on Karaoke {
                id
                title
                slug
                thumbnail {
                  url
                }
                users(first: 10) {
                  data {
                    username
                    id
                    avatar {
                      url
                    }
                  }
                }
              }
              ... on Poem {
                id
                title
                slug
                thumbnail {
                  url
                }
                artists(first: 10) {
                  data {
                    title
                    slug
                    avatar {
                      url
                    }
                  }
                }
              }
              ... on Composer {
                title
                slug
                avatar {
                  url
                }
              }
              ... on Artist {
                title
                slug
                avatar {
                  url
                }
              }
              ... on Recomposer {
                title
                slug
                avatar {
                  url
                }
              }
              ... on Poet {
                title
                slug
                avatar {
                  url
                }
              }
              ... on Document {
                id
                title
                slug
                thumbnail {
                  url
                }
              }
            }
          }
        }
      }
      `;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.mostViewed && data.data.mostViewed.data) {
              this.mostViewed = [];
              data.data.mostViewed.data.map((item) => {
                if (item.object) {
                  this.mostViewed.push(item.object);
                }
              });
              this.shuffleArray(this.mostViewed);
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    shuffleArray(array) {
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
    },
    loadTopUploadDocument() {
      let query = `query {
        topUploadDocument(limit: 5) {
          username
          avatar
          user_id
          total
        }
      }
      `;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.topUploadDocument) {
              this.topUploadDocument = data.data.topUploadDocument;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadActivities() {
      let query = `query {
        activities(first: 10, orderBy: [{column: "id", order: DESC}], where: {AND: [{column: "action", operator: IN, value: ["update_lyric", "create_sheet"]}]}) {
          edges {
            node {
              user {
                id
                username
                avatar {
                  url
                }
              }
              action
              created_at
              object {
                id
                title
                slug
              }
            }
            cursor
          }
        }
      }
      `;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.activities) {
              this.activities = data.data.activities;
              console.log(this.activities.edges.length);
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    loadMostTags() {
      let query = `query {
        tagsWithCount(first: 10) {
          data {
            id
            name
            slug
            sheetCount
          }
        }
      }
      `;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.tagsWithCount && data.data.tagsWithCount.data) {
              this.tags = data.data.tagsWithCount.data;
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    clearPlayer() {
      window.postMessage({action: "clearPlayer"}, window.location.origin);
    },
    numberFormat(x) {
      return numberFormat(x);
    },
    thousandFormat(x) {
      return thousandFormat(x);
    },
    stripContent(content, length) {
      return stripContent(content, length);
    },
    timeago(x) {
      return timeago(x);
    },
    isSongable(x) {
      return isSongable(x);
    },
    isPeople(x) {
      return isPeople(x);
    },
    getPrefixFromSongType(x) {
      return getPrefixFromSongType(x.toLowerCase());
    },
    getRoutePrefixPeople(x) {
      return getRoutePrefixPeople(x.toLowerCase());
    },
    getNameFromPeoplePrefix(x) {
      return getNameFromPeoplePrefix(this.getRoutePrefixPeople(x));
    }
  },
  mounted() {
    this.loadMostViewed();
    // this.loadTopUploadDocument();
    this.loadDocuments();
    this.loadImageDocuments();
    this.loadMostTags();
    this.clearPlayer();
    // this.loadActivities();
    loadNotifications();
    changePageTitle("Bài ca đi cùng năm tháng");
    window.scrollTo(0, 0);
  }
}

</script>
