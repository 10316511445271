<template>
  <div>
    <div class="flex items-center">
      <nav class="responsive-nav -mb-0.5">
        <div class="flex justify-between flex-col-reverse lg:flex-row">
          <nav class="responsive-nav is_ligh -mb-0.5 border-transparent">
            <ul uk-switcher="connect: #discussion-tab; animation: uk-animation-fade">
              <li><a href="#">Nghệ sĩ trẻ với BCĐCNT <i uk-tooltip="title: Đề xuất các nghệ sĩ trẻ tiêu biểu hoặc các tác phẩm do các nghệ sĩ trẻ thể hiện mà bạn thấy phù hợp với phong cách âm nhạc BCĐCNT" class="far fa-question-circle"></i></a></li>
            </ul>
          </nav>
        </div>
      </nav>
      <router-link :to="{name: 'DiscussionAdd', query: {forum_id: 34}}"
                   class="flex items-center justify-center px-3 py-2 rounded-md bg-blue-600 hover:bg-blue-700 hover:text-white text-white space-x-1.5">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5">
          <path fill-rule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z"
                clip-rule="evenodd"></path>
        </svg>
        <span class="whitespace-nowrap">Đề xuất</span>
      </router-link>
    </div>

    <div class="uk-switcher mt-4" id="discussion-tab">
      <div>
        <table v-if="discussions.data && discussions.data.length" class="w-full">
          <tbody>
          <discussion-item-box :discussion="discussion" :index="index + 1"
                               v-for="(discussion, index) in discussions.data" :key="'discussion' + index"/>
          </tbody>
        </table>
        <div class="mt-4">
          <v-pagination
              class="justify-content-end"
              v-if="discussions.paginatorInfo.lastPage > 1"
              v-model="discussions.paginatorInfo.currentPage"
              :pages="discussions.paginatorInfo.lastPage"
              :range-size="1"
              active-color="#DCEDFF"
              @update:modelValue="loadDiscussions"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import DiscussionItemBox from "./DiscussionItemBox";
import {censorBadWords} from "../../core/services/utils.service";

export default {
  name: "DiscussionYoungArtistBox",
  components: {
    DiscussionItemBox
  },
  data() {
    return {
      discussions: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      }
    }
  },
  methods: {
    loadDiscussions() {
      let query = `query($page: Int) {
        discussions(first:5, page: $page, orderBy: [{column: "modified_at", order:DESC}], where: {AND: [{column: "status", value: 1}, {column: "forum_id", value: 34}]}) {
          data {
            id
            title
            slug
            views
            created_at
            comment_count
            thumbnail {
              url
            }
          }
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.discussions.paginatorInfo.currentPage})
          .then(({data}) => {
            if (data.data && data.data.discussions) {
              this.discussions = data.data.discussions;
              if (this.discussions.data && this.discussions.data.length) {
                this.discussions.data.map((item) => {
                  item.title = censorBadWords(item.title);
                });
              }
            }
          })
          .catch((response) => {
            console.log(response);
          });
    }
  },
  mounted() {
    this.loadDiscussions();
  }
}
</script>
